const queryString = require('query-string');

const getDesignTokens = require('@vizia/design-tokens');
const facebookTokenSwapper = require('@vizia/facebook-token-swapper');

const getToken = require('./js/lib/getToken');
const getBundle = require('./js/lib/getBundle');
const bundleMetaData = require('./js/lib/bundleMetadata');
const fetchScripts = require('./js/lib/scriptFetcher');
const AppCoverView = require('./js/views/AppCoverView');
const getConfig = require('./js/conf/clientSideConfig');
const loadTheme = require('./js/lib/loadTheme');
const configUtils = require('./js/lib/configUtils');
const TokenExchanger = require('./js/lib/TokenExchanger');
const computeTokens = require('./js/lib/computeTokens');
const bundleRouter = require('./js/lib/bundleRouter');
const preloadingPipeline = require('./js/lib/preloadingPipeline');
const messageHandler = require('./js/lib/messageHandler');
const hash = require('./js/lib/objectHash');

let refreshTimer;
let tokenPromise;

// Initialise theme as soon as possible
window.VIZIA_CONFIG.then((config) => {
    loadTheme(config)
        .then(() => {
            // Option to inform bundle-loader to set the background color,
            // if not set by the parent of the iframe (e.g. admin3)
            if (config.applyBackground) {
                document.body.style.backgroundColor = 'var(--viz-color-surface)';
            }
        });
});

const appCoverView = new AppCoverView({
    el: document.querySelector('.AppCoverView')
});

function handleBundleRunError(error) {
    showErrorMessage('Error running bundle.');
    return Promise.reject(error);
}

function handleGetManifestError(error) {
    let message;

    if (error.code && error.code > 499 && error.code < 600) {
        message = 'There was an issue with the manifest server.';
    } else if (error.code === 401) {
        message = 'You are not authenticated to view this manifest.';
    } else if (error.code === 403) {
        message = 'You are not authorized to view this manifest.';
    } else if (error.code === 404) {
        message = 'Manifest not found.';
    } else {
        message = `Error communicating with manifest server: ${error.code || 'unknown'}`;
    }

    showErrorMessage(message);

    return Promise.reject(error);
}

function showErrorMessage(message) {
    const secondsToRefresh = 60;
    appCoverView.showMessage(message, 'error', secondsToRefresh);

    clearTimeout(refreshTimer);
    refreshTimer = setTimeout(() => {
        window.location.reload();
    }, secondsToRefresh * 1000);
}

function revealApp() {
    appCoverView.fadeOut();
}

function handleRenderError() {
    appCoverView.showMessage('Could not render App.', 'error');
}

function handleLoadBundleError(error) {
    appCoverView.showMessage('Could not run App.', 'error');
    console.error(error);
    if (window.Rollbar) {
        window.Rollbar.error(error);
    }
}

const clientConf = getConfig();
const search = queryString.parse(location.search);
const bundleName = search.bundle;

const tokenExchanger = new TokenExchanger({
    getToken,
    authServiceRootUrl: clientConf.authServiceRootUrl,
    bundleName: search.bundle
});

function renderBundle(bundle) {
    function reveal() {
        if (typeof bundle.once === 'function' && !bundle.handlesLoading) {
            bundle.once('data', () => {
                revealApp();
            });
            bundle.on('error', err => {
                appCoverView.showMessage(err.message || err, 'error');
                if (isError(err)) {
                    console.error(err);
                }
                bundle.once('data', () => {
                    revealApp();
                });
            });
        } else {
            revealApp();
        }
    }

    return Promise.resolve(bundle.start())
        .then(reveal, handleRenderError);
}

function isError(err) {
    return err.constructor === Error || err.constructor.prototype instanceof Error;
}

function loadBundle([config, initialBundle]) {
    function loadIt(bundle) {
        if (!config.is_public) {
            computeTokens.wrapComputeConfig(bundle, tokenExchanger);
        }

        return bundleRouter(bundle, config)
            .then(() => bundle.load(config))
            .then(() => bundle);
    }

    return loadIt(initialBundle);
}

function setBase(baseUrl) {
    if (!baseUrl) {
        return;
    }

    const baseEl = document.createElement('base');
    baseEl.href = baseUrl[baseUrl.length - 1] === '/' ? `${baseUrl}index.html` : `${baseUrl}/index.html`;
    document.head.appendChild(baseEl);
}

function loadManifestScripts(manifest, config) {
    setBase(manifest.baseUrl);
    return fetchScripts(manifest.browserScripts || [])
        .then(() => fetchScripts(manifest.platform || []))
        .then(() => {
            let app = manifest.artifacts ? manifest.artifacts.full || manifest.app : manifest.app;
            if (shouldLoadServerSideBundle(config, manifest)) {
                app = manifest.artifacts.client;
            }
            return fetchScripts([app]);
        });
}

function mergeConfig(config, tokens, manifest, client) {
    const merged = Object.assign({},
        config,
        manifest.config || {},
        client || {},
        {displayName: manifest.displayName}
    );

    if (shouldLoadServerSideBundle(config, manifest)) {
        merged.remoteBundleId = manifest.artifacts.server;
    }

    if (tokens) {
        merged.scene.options.tokens = tokens;
    }

    if (merged.theme) {
        merged.designTokens = getDesignTokens(config.theme);
    }

    return merged;
}

if (!bundleName) {
    appCoverView.showMessage('No bundle parameter supplied.', 'error');
    throw new Error('No bundle parameter supplied.');
}

function loadManifest() {
    return window.VIZIA_CONFIG.then(config => {
        if (config.is_public) {
            return getBundle.manifest(bundleName, null, config.scene.slide_id);
        }
        return tokenPromise.then(idToken => {
            return getBundle.manifest(bundleName, idToken);
        });
    });
}

function shouldLoadServerSideBundle(config, manifest) {
    return config.scene && ['ready', 'always'].includes(config.scene.serverside) && manifest.artifacts && manifest.artifacts.server && manifest.artifacts.client;
}

tokenPromise = window.VIZIA_CONFIG.then(config => {
    if (config.is_public) {
        return null;
    }
    return getToken();
});

const manifest = loadManifest();

const bundle = config => {
    return manifest
        .then(manifest => loadManifestScripts(manifest, config), handleGetManifestError)
        .then(() => {
            return window.bundle;
        })
        .catch(handleBundleRunError);
};

function getFacebookClientToken(code, tokenUuid) {
    return tokenPromise.then(jwt => {
        return bundleMetaData(bundleName, jwt).then(metadata => {
            const clientId = metadata.auths.find(auth => auth.provider === 'facebook').clientId;
            const redirectUrl = `${clientConf.authServiceRootUrl}${bundleName}/facebook/callback`;
            const tokenIdentifier = `${bundleName}_${tokenUuid}`;
            return facebookTokenSwapper.getToken(code, clientId, redirectUrl, tokenIdentifier);
        });
    });
}

function loadConfig(config) {
    return Promise.all([
        config.then ? config : Promise.resolve(config),
        manifest
    ])
        .then(([config, manifest]) => {
            const tokenIds = configUtils.extractTokenIds(config);

            config.scene.options.tokenIds = tokenIds || {};

            return tokenExchanger.exchange(tokenIds).then(tokens => {
                if (tokens.facebook) {
                    return getFacebookClientToken(tokens.facebook, tokenIds.facebook).then(clientToken => {
                        tokens.facebook = clientToken;
                        delete config.scene.options.tokenIds.facebook;
                        return [config, tokens, manifest];
                    });
                }

                return [config, tokens, manifest];
            });
        })
        .then(([config, tokens, manifest]) => {
            return mergeConfig(config, tokens, manifest, clientConf);
        });
}

function dispatchConfigChange(config, bundle) {
    if (!bundle || !bundle.hotReloadable || !bundle.setConfig) {
        throw new Error('application doesnt support hot reloading');
    }

    bundle.setConfig(config);
}

function setupMessaging([config, bundle]) {
    const bundleFeatures = {};
    if (bundle && bundle.hotReloadable) {
        bundleFeatures.hotReload = true;
    }

    const updateBundleConfig = (action, payload) => {
        loadConfig(payload)
            .then(config => dispatchConfigChange(config, bundle));
    };

    const bundleId = config.scene && config.scene.id && config.scene.id !== 'draft' ? config.scene.id : hash(config);

    messageHandler(bundleId, updateBundleConfig, bundleFeatures);

    return [config, bundle];
}

if (search.preloading) {
    const executionPipeline = preloadingPipeline.createExecutionPipeline(
        loadConfig(window.VIZIA_CONFIG), bundle, [loadBundle, renderBundle]);

    executionPipeline.next(); // This first starts the setup in the generator. (first yield)
    window.addEventListener('message', event => {
        if (event.data && event.data && event.data.preloadState) {
            const pipelineResult = executionPipeline.next(event.data.preloadState);
            // We need to check the value is a promise before adding a catch
            // This is because the value of a generator will return undefined once 'done'
            if (pipelineResult.value instanceof Promise) {
                pipelineResult.value.catch(err => console.error(err));
            }
        }
    });
} else {
    loadConfig(window.VIZIA_CONFIG)
        .then(config => bundle(config).then(bundle => setupMessaging([config, bundle])))
        .then(loadBundle)
        .then(bundle => renderBundle(bundle), handleLoadBundleError)
        .catch(err => {
            console.error(err); // eslint-disable-line no-console
            if (window.Rollbar) {
                window.Rollbar.error(err);
            }
        });
}

window.tokens = tokenExchanger;
