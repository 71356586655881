

const LoadingView = require('./LoadingView');
const MessageView = require('./MessageView');

class AppCoverView {
    constructor(options) {
        if (!options || !(options.el instanceof window.HTMLElement)) {
            throw new Error('AppCoverView requires an element.');
        }
        this.el = options.el;
        this.loadingView = new LoadingView(options);
        this.messageView = new MessageView(options);
    }
    fadeOut() {
        this.loadingView.fadeOut();
        this.messageView.hideMessage();
    }
    showMessage(message, type, refreshTime) {
        this.messageView.showMessage(message, type, refreshTime);
    }
    remove() {
        this.el.remove();
    }
}

module.exports = AppCoverView;
